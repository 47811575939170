import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3563c8e4 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _8ef807e4 = () => interopDefault(import('../pages/adviser/index.vue' /* webpackChunkName: "pages/adviser/index" */))
const _669b773e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _c691f688 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _69172fe6 = () => interopDefault(import('../pages/emailchange.vue' /* webpackChunkName: "pages/emailchange" */))
const _48cc1489 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _c484d224 = () => interopDefault(import('../pages/example/index.vue' /* webpackChunkName: "pages/example/index" */))
const _28e537b8 = () => interopDefault(import('../pages/letter/index.vue' /* webpackChunkName: "pages/letter/index" */))
const _62a4cd2c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _39b8a1fa = () => interopDefault(import('../pages/logoff.vue' /* webpackChunkName: "pages/logoff" */))
const _18c89908 = () => interopDefault(import('../pages/logoffdone.vue' /* webpackChunkName: "pages/logoffdone" */))
const _2727cd17 = () => interopDefault(import('../pages/passwordforget.vue' /* webpackChunkName: "pages/passwordforget" */))
const _546521d6 = () => interopDefault(import('../pages/passwordreset.vue' /* webpackChunkName: "pages/passwordreset" */))
const _23de4fc9 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _1a410f32 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _533ac4b4 = () => interopDefault(import('../pages/registerdone.vue' /* webpackChunkName: "pages/registerdone" */))
const _996e12ea = () => interopDefault(import('../pages/rule.vue' /* webpackChunkName: "pages/rule" */))
const _55fb03b5 = () => interopDefault(import('../pages/seminar/index.vue' /* webpackChunkName: "pages/seminar/index" */))
const _973aaff4 = () => interopDefault(import('../pages/serial/index.vue' /* webpackChunkName: "pages/serial/index" */))
const _594bbd28 = () => interopDefault(import('../pages/tags.vue' /* webpackChunkName: "pages/tags" */))
const _75e54bda = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _bda93e70 = () => interopDefault(import('../pages/topic/tags.vue' /* webpackChunkName: "pages/topic/tags" */))
const _695da9f6 = () => interopDefault(import('../pages/adviser/_id.vue' /* webpackChunkName: "pages/adviser/_id" */))
const _8294273c = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _6c007cd6 = () => interopDefault(import('../pages/example/_id.vue' /* webpackChunkName: "pages/example/_id" */))
const _154dc820 = () => interopDefault(import('../pages/letter/_id.vue' /* webpackChunkName: "pages/letter/_id" */))
const _ff1f2146 = () => interopDefault(import('../pages/seminar/_id.vue' /* webpackChunkName: "pages/seminar/_id" */))
const _fb321824 = () => interopDefault(import('../pages/serial/_id.vue' /* webpackChunkName: "pages/serial/_id" */))
const _5c56c54b = () => interopDefault(import('../pages/topic/_name.vue' /* webpackChunkName: "pages/topic/_name" */))
const _0505f653 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _3563c8e4,
    name: "about"
  }, {
    path: "/adviser",
    component: _8ef807e4,
    name: "adviser"
  }, {
    path: "/contact",
    component: _669b773e,
    name: "contact"
  }, {
    path: "/cookie-policy",
    component: _c691f688,
    name: "cookie-policy"
  }, {
    path: "/emailchange",
    component: _69172fe6,
    name: "emailchange"
  }, {
    path: "/error",
    component: _48cc1489,
    name: "error"
  }, {
    path: "/example",
    component: _c484d224,
    name: "example"
  }, {
    path: "/letter",
    component: _28e537b8,
    name: "letter"
  }, {
    path: "/login",
    component: _62a4cd2c,
    name: "login"
  }, {
    path: "/logoff",
    component: _39b8a1fa,
    name: "logoff"
  }, {
    path: "/logoffdone",
    component: _18c89908,
    name: "logoffdone"
  }, {
    path: "/passwordforget",
    component: _2727cd17,
    name: "passwordforget"
  }, {
    path: "/passwordreset",
    component: _546521d6,
    name: "passwordreset"
  }, {
    path: "/privacy",
    component: _23de4fc9,
    name: "privacy"
  }, {
    path: "/register",
    component: _1a410f32,
    name: "register"
  }, {
    path: "/registerdone",
    component: _533ac4b4,
    name: "registerdone"
  }, {
    path: "/rule",
    component: _996e12ea,
    name: "rule"
  }, {
    path: "/seminar",
    component: _55fb03b5,
    name: "seminar"
  }, {
    path: "/serial",
    component: _973aaff4,
    name: "serial"
  }, {
    path: "/tags",
    component: _594bbd28,
    name: "tags"
  }, {
    path: "/user",
    component: _75e54bda,
    name: "user"
  }, {
    path: "/topic/tags",
    component: _bda93e70,
    name: "topic-tags"
  }, {
    path: "/adviser/:id",
    component: _695da9f6,
    name: "adviser-id"
  }, {
    path: "/article/:id?",
    component: _8294273c,
    name: "article-id"
  }, {
    path: "/example/:id",
    component: _6c007cd6,
    name: "example-id"
  }, {
    path: "/letter/:id",
    component: _154dc820,
    name: "letter-id"
  }, {
    path: "/seminar/:id",
    component: _ff1f2146,
    name: "seminar-id"
  }, {
    path: "/serial/:id",
    component: _fb321824,
    name: "serial-id"
  }, {
    path: "/topic/:name?",
    component: _5c56c54b,
    name: "topic-name"
  }, {
    path: "/",
    component: _0505f653,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
